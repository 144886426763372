#hwx-lmdb-events-list.hwx-lmdb-list-tiles {
    > .hwx-lmdb-list-items {
        .hwx-lmdb-list-item {
            .hwx-lmdb-list-item-location {
                @include font-size(12px);
            }

            .hwx-lmdb-list-item-category {
                @include padding-left(15px);
                @include padding-right(15px);
                color            : $white;
                background-color : $lmdb-category-title-color;

                .hwx-lmdb-list-item-category-text {
                    margin-bottom : 0;
                    line-height   : 1.5;
                }
            }

            .hwx-lmdb-list-item-image {
                @include height(250px);
                overflow : hidden;
            }

            .hwx-lmdb-list-item-content {
                .hwx-lmdb-list-item-content-title {
                    .hwx-lmdb-list-item-content-title-text {
                        @include font-size(20px);
                        font-weight : $font-weight-semibold;
                    }
                }
            }
        }
    }
}
