#hwx-lmdb-company-detail {
    .hwx-lmdb-detail-item {
        .hwx-lmdb-detail-item-details {
            @include margin-top(30px);
            @include grid-gap(30px);
            display               : grid;
            grid-template-columns : 1fr;

            @include media-breakpoint-up(lg) {
                grid-template-columns : 1fr 1fr 1fr;
            }
        }

        .hwx-lmdb-job-offers,
        .hwx-lmdb-order-options {
            .hwx-lmdb-detail-content {
                .list-group {
                    .list-group-item {
                        border : none;

                        i,
                        svg {
                            @include font-size(24px);
                            color : $success;
                        }
                    }
                }
            }
        }

        .hwx-lmdb-contacts {
            .hwx-lmdb-detail-content {
                display        : flex;
                flex-direction : column;

                .hwx-lmdb-contact {
                    @include padding(10px);

                    &:nth-child(odd) {
                        background-color : rgba($black, 0.05);
                    }
                }
            }
        }
    }
}
