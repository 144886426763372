.hwx-lmdb-list-baselist {
    > .hwx-lmdb-list-items {
        @include gap(20px);
        display        : flex;
        flex-direction : column;

        @include media-breakpoint-up(lg) {
            @include gap(30px);
        }

        .hwx-lmdb-list-item {
            display        : flex;
            flex-direction : row;

            .hwx-lmdb-list-item-date {
                @include max-width(80);

                > div {
                    @include padding-top(10px);
                    @include padding-bottom(10px);
                    @include font-size(18px);
                    color            : $gray-900;
                    background-color : $gray-200;
                    line-height      : 1;
                    font-weight      : $font-weight-semibold;
                    text-align       : center;
                }
            }

            .hwx-lmdb-list-item-content {
                .hwx-lmdb-list-item-content-title {
                    @include margin-bottom(5px);

                    .hwx-lmdb-list-item-content-title-text {
                        margin-bottom : 0;
                    }
                }

                .hwx-lmdb-list-item-content-text {
                    @include margin-top(15px);
                    @include margin-bottom(15px);

                    p {
                        &:last-of-type {
                            margin-bottom : 0;
                        }
                    }
                }
            }
        }
    }

    > .hwx-lmdb-list-items-slidered {
        @include make-vertical-spacing(30);

        .carousel-cell {
            @include margin-right(30px);
            width : 70%;

            @include media-breakpoint-up(sm) {
                width : 40%;
            }

            @include media-breakpoint-up(lg) {
                width : 20%;
            }
        }

        .hwx-lmdb-list-item {
            background-color : transparent;
            flex-direction   : column;

            .hwx-lmdb-list-item-content-title {
                .hwx-lmdb-list-item-content-title-text {
                    @include font-size(16px);
                    @include height(50);
                    overflow      : hidden;
                    text-align    : left;
                    margin-bottom : 0;

                    a {
                        color : $gray-800;
                    }
                }
            }

            .hwx-lmdb-list-item-image {
                @include height(250);
                position : relative;
                overflow : hidden;
            }
        }
    }
}
