#hwx-lmdb-product-detail {
    .hwx-lmdb-detail-item {
        .hwx-lmdb-detail-item-product {
            div.hwx-lmdb-detail-item-image {
                @include top(15);
                position : sticky;

                div.hwx-lmdb-detail-item-imageblock {
                    width         : 100%;
                    float         : none;
                    margin-bottom : 0;
                    margin-left   : 0;

                    img {
                        @include rem(border-radius, 20);
                    }
                }

                .hwx-lmdb-price-container {
                    @include padding(5px);
                    position         : absolute;
                    top              : 0;
                    right            : 0;
                    background-color : $white;
                    line-height      : 1;

                    .hwx-line-through-eu,
                    .hwx-eu {
                        @include right(5);
                    }

                    .hwx-line-through-price {
                        @include font-size(24px);
                    }

                    .hwx-line-through-eu {
                        @include font-size(13px);
                        @include top(-10);
                    }

                    .hwx-price {
                        @include font-size(45px);
                    }

                    .hwx-eu {
                        @include font-size(30px);
                        @include top(-15);
                    }
                }

                .hwx-lmdb-item-image-footnote {
                    font-size   : 80%;
                    font-weight : $font-weight-normal;
                    text-align  : right;
                }
            }

            .hwx-lmdb-detail-item-body {
                .hwx-lmdb-detail-item-delivery-note {
                    @include padding(5px 15px);
                    border        : 1px solid $gray-300;
                    border-bottom : 0;

                    .hwx-lmdb-detail-item-delivery-note-label {
                        font-weight : $font-weight-medium;
                    }
                }
            }
        }

        .hwx-lmdb-detail-marketplace-provider {
            @include margin-top(50px);

            .hwx-lmdb-detail-marketplace-provider-content {
                @include padding(10px);
                @include font-size(13px);
                border     : 1px solid $gray-300;
                background : $gray-200;
                position   : relative;

                .hwx-lmdb-detail-marketplace-provider-address,
                .hwx-lmdb-detail-marketplace-provider-order,
                .hwx-lmdb-detail-marketplace-provider-openinghours {

                }

                .hwx-lmdb-detail-marketplace-provider-title {
                    @include top(-(30));
                    @include left(10);
                    position   : absolute;
                    background : $gray-200;

                    .hwx-lmdb-detail-marketplace-provider-title-text {
                        @include padding(5px 10px);
                        @include font-size(24px);
                        line-height   : 1;
                        font-weight   : $font-weight-normal;
                        margin-bottom : 0;
                    }
                }

                .hwx-lmdb-detail-marketplace-provider-address,
                .hwx-lmdb-detail-marketplace-order {
                    @include margin-top(10px);
                    @include margin-bottom(10px);
                }

                .hwx-lmdb-detail-marketplace-openinghours {
                    @include margin-top(10px);
                }

                .hwx-lmdb-detail-marketplace-provider-buttons {
                    @include margin-top(15px);
                    @include margin-bottom(15px);
                    @include make-horizontal-spacing(10);
                    display : flex;

                    .hwx-lmdb-detail-marketplace-provider-button {
                        i,
                        svg {
                            margin-right : 0;
                        }

                        .btn {
                            $btn-size       : 40;

                            @include max-width($btn-size);
                            @include height($btn-size);
                            border-radius   : 50%;
                            display         : flex;
                            align-items     : center;
                            flex            : 1 0 rem($btn-size);
                            justify-content : center;
                        }
                    }
                }
            }

            .hwx-lmdb-detail-item-mapblock {
                height : 100%;
                width  : 100%;

                #hwx-lmdb-detail-item-map {
                    height : 100%;
                }
            }

            .hwx-lmdb-detail-marketplace-provider-more {
                @include margin-top(50px);

                .hwx-lmdb-detail-marketplace-provider-more-products {
                    > .row {
                        @include make-vertical-spacing()
                    }

                    .hwx-lmdb-detail-marketplace-provider-more-product {
                        a {
                            text-decoration : none;
                            color           : $secondary;
                        }

                        img {
                            @include height(250);
                            width           : 100%;
                            object-fit      : cover;
                            object-position : top center;
                        }

                        .hwx-lmdb-detail-marketplace-provider-more-product-title {
                            @include height(50);
                            display        : flex;
                            flex-direction : row;
                            align-items    : center;

                            .hwx-lmdb-detail-marketplace-provider-more-product-title-text {
                                @include padding-left(10px);
                                margin-bottom : 0;
                            }
                        }

                        .hwx-lmdb-detail-marketplace-provider-more-product-price {
                            position : relative;

                            .hwx-lmdb-price-container {
                                @include padding(5px);
                                position         : absolute;
                                top              : 0;
                                right            : 0;
                                background-color : $white;
                                line-height      : 1;

                                .hwx-price,
                                .hwx-line-through-price {
                                    font-weight : $font-weight-semibold;
                                }

                                .hwx-line-through-price {
                                    @include font-size(16px);
                                    text-decoration : line-through;
                                }

                                .hwx-line-through-eu,
                                .hwx-eu {
                                    @include top(-5);
                                    @include font-size(10px);
                                    @include right(5);
                                }

                                .hwx-price {
                                    @include font-size(25px);
                                }

                                .hwx-eu {
                                    @include font-size(18px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
