.hwx-lmdb-list-basetiles {
    > .hwx-lmdb-list-items {
        @include rem(gap, 30);
        display               : grid;
        grid-template-columns : repeat(auto-fill, minmax(250px, 1fr));

        .hwx-lmdb-list-item {
            display        : flex;
            flex-direction : column;

            &.is-top .hwx-badges-bar .hwx-badge-top,
            &.is-new .hwx-badges-bar .hwx-badge-new,
            &.is-tip .hwx-badges-bar .hwx-badge-tip {
                opacity : 1;
            }

            &.is-tip {
                border : 2px solid $red-500;
            }

            .hwx-badges-bar {
                .hwx-lmdb-list-item-badge {
                    opacity : 0;
                }
            }
        }
    }
}
