.hwx-lmdb-marketplace-module-default {
    .hwx-lmdb-list-items {
        @include make-vertical-spacing($grid-gutter-width-half);

        .hwx-lmdb-list-item {
            .hwx-lmdb-list-item-content-title {
                @include height(50);
                display          : flex;
                flex-direction   : row;

                .hwx-lmdb-list-item-content-title-text {
                    @include padding-left(10px);
                    align-self    : center;
                    width         : 100%;
                    text-align    : left;
                    margin-bottom : 0;

                    a {
                        color : $gray-900;
                    }
                }
            }

            .hwx-lmdb-list-item-image {
                position : relative;

                .hwx-lmdb-vendor-logo {
                    position : absolute;
                    bottom   : 0;
                    right    : 0;

                    img {
                        @include rem(max-height, 25);
                    }
                }

                a {
                    img {
                        @include height(275);
                        width           : 100%;
                        object-fit      : cover;
                        object-position : center center;
                    }
                }

                .hwx-lmdb-price-container {
                    @include padding(5px);
                    position         : absolute;
                    top              : 0;
                    right            : 0;
                    background-color : $white;
                    line-height      : 1;

                    .hwx-line-through-eu,
                    .hwx-eu {
                        @include right(5);
                    }

                    .hwx-line-through-price {
                        @include font-size(16px);
                    }

                    .hwx-line-through-eu {
                        @include font-size(13px);
                    }

                    .hwx-price {
                        @include font-size(25px);
                    }

                    .hwx-eu {
                        @include font-size(18px);
                    }
                }
            }
        }
    }

    .hwx-lmdb-view-all {
        @include margin-top($grid-gutter-width-half);
        text-align : center;
    }
}
