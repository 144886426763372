.hwx-lmdb-marketplace-module-small {
    .hwx-lmdb-list-items {
        .hwx-lmdb-list-item {
            @include height(70);
            display  : flex;
            overflow : hidden;
            position : relative;

            .hwx-lmdb-list-item-image {
                @include margin-right(5px);
                flex        : 1 0 25%;
                max-width   : 25%;
                display     : flex;
                align-items : center;
            }

            .hwx-lmdb-list-item-content {
                display        : flex;
                flex-direction : column;
                flex           : 1;

                .hwx-lmdb-list-item-content-title {
                    flex       : 1 0 67%;
                    max-height : 67%;

                    .hwx-lmdb-list-item-content-title-text {
                        @include max-height(40);
                        overflow : hidden;

                        a {
                            @include font-size(15px);
                            text-decoration : none;
                            display         : block;
                            font-weight     : $font_weight_semibold;
                        }
                    }
                }

                .hwx-lmdb-price-container {
                    flex       : 1 0 33%;
                    max-height : 33%;
                }
            }

            .hwx-lmdb-vendor-logo {
                position  : absolute;
                bottom    : 0;
                right     : 0;
                max-width : 20%;
            }
        }
    }

    .hwx-lmdb-view-all {
        @include margin-top($grid-gutter-width-half);
        text-align : center;
    }
}
