#hwx-lmdb-events-list.hwx-lmdb-list-default {
    > .hwx-lmdb-list-items {
        @include rem(grid-row-gap, 15);
        display       : grid;
        grid-template : 1fr / auto;

        @include media-breakpoint-up(sm) {
            @include rem(grid-column-gap, 15);
            grid-template-columns : repeat(2, 1fr);
        }

        @include media-breakpoint-up(md) {
            @include rem(grid-column-gap, 20);
            @include rem(grid-row-gap, 20);
        }

        @include media-breakpoint-up(xl) {
            @include rem(grid-column-gap, 30);
            @include rem(grid-row-gap, 30);
            grid-template-columns : repeat(3, 1fr);
        }

        .hwx-lmdb-list-item {
            display             : grid;
            grid-template-areas : "date" "image" "content";
            grid-template-rows  : 0 200px auto;
            border-radius       : 0 0 5px 5px;

            .hwx-lmdb-list-item-date {
                @include padding(10px 15px);
                color            : $white;
                background-color : rgba($black, 0.75);
                grid-area        : date;
                position         : absolute;
                top              : 0;
                right            : 0;
                border-radius    : 0 0 0 10px;

                > div {
                    padding          : 0;
                    background-color : unset;
                    color            : $white;
                }
            }

            .hwx-lmdb-list-item-content {
                @include padding(20px);
                grid-area             : content;
                display               : grid;
                grid-template-columns : 1fr;
                grid-template-areas   : "title" "details";

                .hwx-lmdb-list-item-content-details {
                    @include font-size(13px);
                    grid-area     : details;
                    margin-bottom : 0;
                }

                .hwx-lmdb-list-item-content-title {
                    $max-lines : 2;

                    @include multiline-truncate($max-lines);
                    grid-area  : title;
                }
            }

            .hwx-lmdb-list-item-image {
                grid-area : image;
                overflow  : hidden;
            }

            &.has-text {
                .hwx-lmdb-list-item-content {
                    grid-template-rows  : auto auto 1fr;
                    grid-template-areas : "title" "details" "text";

                    .hwx-lmdb-list-item-content-text {
                        @include font-size(14px);
                        grid-area     : text;
                        margin-bottom : 0;
                    }
                }
            }
        }

        .hwx-lmdb-list-advertisement {
            background-color : gray;
            grid-column      : 1 / span 1;

            @include media-breakpoint-up(sm) {
                grid-column-end : span 2;
            }
            @include media-breakpoint-up(xl) {
                grid-column-end : span 3;
            }
        }
    }
}
