.hwx-lmdb-marketplace-module-grid {
    @extend #hwx-lmdb-products-list;

    @include margin-bottom(50px);

    .hwx-lmdb-list-items {
        .hwx-lmdb-list-item {
            .hwx-lmdb-list-item-image {
                position : relative;

                .hwx-lmdb-vendor-logo {
                    position : absolute;
                    bottom   : 0;
                    right    : 0;

                    img {
                        @include rem(max-height, 25);
                    }
                }
            }
        }
    }

    > .hwx-lmdb-view-all {
        @include margin-top(30px);
        text-align : center;
    }
}
