.hwx-lmdb-goodnews-standalone-module {
    $position-top : 170px;

    @include right(-300px);
    @include top($position-top);
    z-index       : 9999;
    position      : fixed;
    display       : flex;
    transition    : right 0.3s ease-in-out;

    @include media-breakpoint-up(sm) {
        @include right(-400px);
    }

    &.expanded {
        right : 0;
    }

    .hwx-lmdb-goodnews-button {
        align-self : start;

        img {
            @include width(220px);
        }
    }

    .hwx-lmdb-goodnews-news-items {
        @include width(250px);
        @include font-size(14px);
        max-height       : calc(100vh - #{$position-top});
        background-color : #ffffff;
        overflow-y       : auto;

        @include media-breakpoint-up(sm) {
            @include width(350px);
        }

        .hwx-lmdb-goodnews-news-items-header-text {
            @include padding(15px);
            margin : 0;
        }

        .hwx-lmdb-goodnews-news-item {
            @include padding(15px);
            position : relative;

            &:hover {
                background-color : rgba(58, 114, 183, 0.10);
            }

            .hwx-lmdb-goodnews-news-item-date {
                @include font-size(12px);
            }

            .hwx-lmdb-goodnews-news-item-title {
                @include font-size(15px);
                line-height : 1.2;
                font-weight : 500;
            }

            .hwx-lmdb-goodnews-news-item-teaser {
                @include margin-top(15px);
                line-height : 1.1;
            }

            .hwx-lmdb-goodnews-news-item-link {
                &:after {
                    position : absolute;
                    top      : 0;
                    right    : 0;
                    bottom   : 0;
                    left     : 0;
                    z-index  : 1;
                    content  : "";
                }
            }
        }

        .hwx-lmdb-view-all {
            @include margin-top(15px);

            a {
                width            : 100%;
                display          : inline-block;
                font-weight      : 400;
                line-height      : 1.5;
                background-color : #1eb4bc;
                text-align       : center;
                text-decoration  : none;
                vertical-align   : middle;
                cursor           : pointer;
                user-select      : none;
                border           : 1px solid #1eb4bc;
                padding          : 0.375rem 0.75rem;
                font-size        : 1rem;
                border-radius    : 0;
                transition       : color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

                @include hover-focus-active() {
                    background-color : darken(#1eb4bc, 3%);
                    border-color     : darken(#1eb4bc, 3%);
                }
            }
        }
    }
}
