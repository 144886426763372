#hwx-lmdb-products-list.hwx-lmdb-list-default {
    .hwx-lmdb-list-items {
        @include rem(gap, 30);
        display               : grid;
        grid-template-columns : repeat(auto-fill, minmax(250px, 1fr));

        .hwx-lmdb-list-item {
            display        : flex;
            flex-direction : column;

            .hwx-lmdb-list-item-content-title {
                background : unset;

                .hwx-lmdb-list-item-content-title-text {
                    @include font-size(16px);
                    text-align : left;

                    a {
                        color : $gray-800;
                    }
                }
            }

            .hwx-lmdb-list-item-image {
                @include height(250);
                position  : relative;
                max-width : none;
            }

            .hwx-lmdb-list-item-content {
                flex : 1 0 auto;

                .hwx-lmdb-list-item-content-text {
                    @include rem(min-height, 100);

                    > a {
                        text-decoration : none;
                    }

                    p {
                        @include font-size(13px);
                        @include margin-bottom(10px);
                    }
                }
            }

            .hwx-lmdb-list-marketplace-provider {
                .hwx-lmdb-list-marketplace-provider-content {
                    $padding         : 15px;

                    @include padding($padding);
                    @include margin-top(15px);
                    @include font-size(13px);
                    background-color : $gray-200;
                    position         : relative;
                    border           : 1px solid $gray-300;

                    .hwx-lmdb-list-marketplace-provider-title {
                        @include top(-$padding / 2);
                        @include left($padding);
                        position         : absolute;
                        background-color : $gray-200;

                        .hwx-lmdb-list-marketplace-provider-title-text {
                            @include font-size(13px);
                            font-weight   : $font-weight-normal;
                            margin-bottom : 0;
                        }
                    }

                    .hwx-lmdb-list-marketplace-provider-logo {
                        $imageHeight : 25;

                        @include top(-$imageHeight / 2);
                        @include right($padding);
                        position     : absolute;

                        img {
                            @include rem(max-height, $imageHeight);
                        }
                    }

                    .hwx-lmdb-list-marketplace-provider-address {
                        @include font-size(10px);
                        @include margin-bottom(5px);
                    }

                    .hwx-lmdb-list-marketplace-provider-buttons {
                        @include margin-top(5px);
                        @include margin-bottom(5px);

                        i,
                        svg {
                            margin-right : 0;
                        }

                        .btn {
                            @include width(40);
                            @include height(40);
                            border-radius   : 50%;
                            display         : flex;
                            align-items     : center;
                            justify-content : center;
                        }
                    }
                }
            }
        }
    }
}
