#hwx-lmdb-companies-list.hwx-lmdb-list-default {
    .hwx-lmdb-list-items {
        @include rem(gap, 30);
        display               : grid;
        grid-template-columns : repeat(auto-fill, minmax(250px, 1fr));

        .hwx-lmdb-list-item {
            .hwx-lmdb-list-item-content {
                position : relative;

                .hwx-lmdb-list-item-image {
                    @include margin-bottom(10px);
                    max-width : none;

                }

                .hwx-lmdb-list-item-content-title {
                    .hwx-lmdb-list-item-content-title-text {
                        @include font-size(16px);
                        @include height(50);
                        overflow      : hidden;
                        text-align    : left;
                        margin-bottom : 0;
                    }
                }

                .hwx-lmdb-list-item-content-text {
                    @include rem(min-height, 100);
                    @include font-size(13px);
                }
            }
        }
    }
}
