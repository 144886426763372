#hwx-lmdb-event-detail {
    .hwx-lmdb-detail-item {
        .hwx-lmdb-detail-item-description,
        .hwx-lmdb-detail-item-details {
            @include margin-bottom(30px);
        }

        .hwx-lmdb-detail-item-details {
            .hwx-lmdb-detail-item-detail {
                display : flex;
            }
        }
    }
}
