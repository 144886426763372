.hwx-lmdb-basedetail {
    i,
    svg {
        @include margin-right(5px);
    }

    > .hwx-lmdb-detail-item {
        @include clearfix;

        .hwx-lmdb-detail-item-imageblock,
        .hwx-lmdb-detail-item-videoblock,
        .hwx-lmdb-detail-item-mapblock {
            @include margin-bottom(30px);
            text-align : center;

            @include media-breakpoint-up(lg) {
                @include margin-left(60px);
                float      : right;
                width      : 50%;
                text-align : start;
            }
        }

        .hwx-lmdb-detail-item-videoblock,
        .hwx-lmdb-detail-item-imageblock {
            .figure {
                margin-bottom : 0;
                display       : block;

                & + figure {
                    @include margin-top(15px);
                }

                img,
                video {
                    width : 100%;
                }

                .figure-caption {
                    @include margin-top(5px);
                    text-align : right;
                }
            }
        }

        .hwx-lmdb-detail-item-mapblock {
            #hwx-lmdb-detail-item-map {
                @include min-height(300);
            }
        }

        .hwx-lmdb-detail-item-details,
        .hwx-lmdb-detail-item-attachments-block,
        .hwx-lmdb-detail-item-body {
            @include margin-top(30px);
            @include margin-bottom(30px);
        }

        .hwx-lmdb-detail-item-attachments-block {
            .hwx-lmdb-attachment {
                position : relative;
            }
        }

        .hwx-lmdb-detail-item-body {
            .hwx-lmdb-detail-item-shoplink,
            .hwx-lmdb-detail-item-description {
                @include margin-top(15px);
                @include margin-bottom(15px);
            }

            .hwx-lmdb-detail-item-teaser {
                @include margin-bottom(30px);
            }

            .hwx-lmdb-detail-item-description-text {
                p {
                    &:last-of-type {
                        margin-bottom : 0;
                    }
                }
            }

            .hwx-lmdb-detail-item-contactbox {
                @include padding(15px);
                background-color : $gray-200;
                display          : flex;
                flex-wrap        : wrap;

                .hwx-lmdb-detail-item-contact-buttons,
                .hwx-lmdb-detail-item-contactbox-title {
                    align-self : center;
                }

                .hwx-lmdb-detail-item-contactbox-title {
                    flex : 1;

                    .hwx-lmdb-detail-item-contactbox-title-text {
                        @include font-size(20px);
                        margin-bottom : 0;
                    }
                }

                .hwx-lmdb-detail-item-contact-buttons {
                    @include make-horizontal-spacing(10);
                    @include width(200);
                    flex            : 0 1 rem(200);
                    display         : flex;
                    flex-wrap       : wrap;
                    margin-left     : auto;
                    justify-content : flex-end;

                    .hwx-lmdb-detail-item-contact-button {
                        i,
                        svg {
                            margin-right : 0;
                        }

                        .btn {
                            $btn-size       : 40;

                            @include max-width($btn-size);
                            @include height($btn-size);
                            border-radius   : 50%;
                            display         : flex;
                            align-items     : center;
                            flex            : 1 0 rem($btn-size);
                            justify-content : center;
                        }
                    }
                }

                .hwx-lmdb-detail-item-contactform {
                    @include margin-top(15px);
                    flex : 1 0 100%;

                    .rsform-block {
                        &:not(.rsform-block-interest) {
                            label {
                                display : none;
                            }
                        }

                        &.rsform-block-dsgvo {
                            @include font-size(11px);
                        }

                        &.rsform-block-send {
                            margin-bottom : 0;
                        }
                    }
                }
            }
        }

        .hwx-lmdb-branches {
            .hwx-lmdb-detail-content {
                @include gap(5px);
                display   : flex;
                flex-wrap : wrap;
            }
        }

        .hwx-lmdb-detail-item-details {
            @include gap(5px);
            display        : flex;
            flex-direction : column;

            .hwx-lmdb-detail-item-detail {
                @include gap(5px);
                display     : flex;
                align-items : center;

                &.hwx-lmdb-detail-item-detail-socialmedia {
                    a {
                        text-decoration : none;
                    }
                }
            }
        }

        .hwx-lmdb-detail-item-linked-content {
            > div + div {
                @include margin-top(40px);
            }

            .hwx-lmdb-linked-item {
                position : relative;
            }
        }
    }

    .hwx-lmdb-detail-items-slidered {
        .carousel-cell {
            @include margin-right(30px);
            width : 70%;

            @include media-breakpoint-up(sm) {
                width : 40%;
            }

            @include media-breakpoint-up(lg) {
                width : 20%;
            }
        }
    }
}
