#hwx-lmdb-events-list .hwx-lmdb-toplist,
#hwx-lmdb-events-list.hwx-lmdb-list-rows {
    > .hwx-lmdb-list-items {
        .hwx-lmdb-list-item {
            .hwx-lmdb-list-item-content,
            .hwx-lmdb-list-item-image,
            .hwx-lmdb-list-item-date {
                flex : 1 1 100%;
            }

            .hwx-lmdb-list-item-content {
                .hwx-lmdb-list-item-content-details {
                    @include media-breakpoint-only(xs) {
                        display : block;
                    }
                }
            }

            .hwx-lmdb-list-item-image {
                @include height(350);
                overflow   : hidden;
                max-width  : 20vw;

            }

            @include media-breakpoint-down(md) {
                .hwx-lmdb-list-item-date {
                    display : none;
                }

                .hwx-lmdb-list-item-content {
                    max-width : 80vw;
                }
            }
        }
    }
}
