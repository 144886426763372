#hwx-lmdb-filter {
    $hwx-filter-input-size : 40;

    @include margin-bottom(30px);

    @include media-breakpoint-up(md) {
        @include margin-bottom(50px);
    }

    .input-group {
        @include height($hwx-filter-input-size);
    }

    .hwx-lmdb-filter-types {
        @include gap($grid-gutter-width-half);
        display   : flex;
        flex-wrap : wrap;

        @include media-breakpoint-up(lg) {
            @include gap(30px)
        }

        #hwx-lmdb-filter-category {
            flex : 1 0 100%;
        }
    }

    .filter-button {
        background-color : $gray-300;
        border           : 1px solid $gray-300;
        color            : $black;
        border           : unset;

        @include hover-focus-active() {
            background-color : $gray-400;
            border-color     : $gray-400;
        }

        &.active {
            background-color : $gray-500;
            border-color     : $gray-500;
        }
    }

    .hwx-lmdb-filter-label {
        @include margin-bottom(5px);
        @include rem(letter-spacing, 1);
        font-weight    : $font-weight-bold;
        text-transform : uppercase;
    }

    .hwx-lmdb-filter-button-container {
        @include rem(border-radius, 5);
        @include margin(-2px);
        border          : 1px solid $gray-300;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : space-between;

        .filter-button {
            @include font-size(14px);
            @include margin(2px);
            @include padding-top(3px);
            @include padding-bottom(3px);
            flex : 1 1 auto;

            &.filter-button-all {
                background-color : $gray-500;
                border-color     : $gray-500;
            }
        }
    }

    #hwx-lmdb-filter-branch {
    }

    #hwx-lmdb-filter-category {
    }

    #hwx-lmdb-filter-location {
    }

    #hwx-lmdb-filter-date {
        .hwx-lmdb-filter-input-container {
            @include gap($grid-gutter-width);
            display : flex;

            .field-calendar {
                .filter-button {
                    i,
                    svg {
                        margin-right : 0;
                    }
                }
            }
        }
    }

    #hwx-lmdb-filter-reset {
        display     : flex;
        align-items : flex-end;

        #hwx-lmdb-filter-reset-button {
            @include height($hwx-filter-input-size);
        }
    }
}
