.hwx-lmdb-list-basetiles,
.hwx-lmdb-list-baselist {
    i,
    svg {
        @include margin-right(5px);
    }

    .hwx-lmdb-list-topitems {
        @include margin-bottom(50px);

        @include media-breakpoint-up(md) {
            @include margin-bottom(75px);
        }

        @include media-breakpoint-up(xl) {
            @include margin-bottom(100px);
        }
    }

    .hwx-lmdb-list-item,
    .hwx-lmdb-list-item-content-wrapper {
        position : relative;
    }

    .hwx-lmdb-list-item {
        @include font-size(15px);
        background-color : $gray-200;

        @include hover-focus-active() {
            box-shadow : 0 0.5rem 1rem rgba($black, 0.2);
        }

        .hwx-lmdb-list-item-content {
            @include padding(15px);

            .hwx-lmdb-list-item-content-title-text {
                color : $lmdb-title-color;
            }

            .hwx-lmdb-list-item-content-details {
                @include margin-bottom(15px);
                display        : flex;
                color          : $lmdb-details-color;
                font-style     : italic;
                flex-direction : column;
                flex-wrap      : nowrap;

                .hwx-lmdb-list-item-content-date {
                    font-weight : $font-weight-semibold;
                }

                .hwx-lmdb-list-item-content-detail {
                    display : flex;

                    &.hwx-lmdb-list-item-content-location {
                        flex : 1 1 100%;
                    }
                }
            }
        }

        .hwx-lmdb-contact {
            @include font-size(13px);
            @include padding(15px);
        }
    }
}

.hwx-lmdb-price-container {
    @include padding(5px);
    position         : absolute;
    top              : 0;
    right            : 0;
    background-color : $white;
    line-height      : 1;

    .hwx-price,
    .hwx-current-price {
        font-weight : $font-weight-semibold;
    }

    .hwx-price {
        @include font-size(25px);
    }

    .hwx-eu {
        @include font-size(18px);
    }

    .hwx-line-through-eu,
    .hwx-line-through-price {
        @include font-size(13px);
    }

    .hwx-line-through-price,
    .hwx-line-through-eu {
        color : $red;
    }

    .hwx-line-through-eu,
    .hwx-eu {
        @include top(-5);
        @include right(5);
        right    : 0;
        position : relative;
    }

    .hwx-line-through-price {
        text-decoration : line-through;
    }
}

.hwx-lmdb-list-pagination {
    @include margin-top(50px);

    ul.pagination {
        margin-bottom : 0;
    }
}

.hwx-lmdb-list-footnote {
    @include margin-top(15px);
    font-size   : 80%;
    font-weight : $font-weight-normal;
    text-align  : right;
}

.hwx-lmdb-list-poweredby {
    @include margin-top(30px);
    text-align : center;

    img {
        @include margin-left(5px);
        @include max-width(250);
    }
}

.hwx-float-top-left {
    position : absolute;
    top      : 0;
    left     : 0;
}

.hwx-badges-bar {
    @include line-height(20);
    @include font-size(12px);
    display        : flex;
    flex-direction : row;
    flex-wrap      : nowrap;
    color          : $white;
    font-weight    : $font-weight-bold;
    text-transform : uppercase;
    text-align     : center;

    .hwx-lmdb-list-item-badge {
        @include padding-left(2px);
        @include padding-right(2px);
        width : 100%;

        &.hwx-badge-new {
            background-color : $green-300;
        }

        &.hwx-badge-tip {
            background-color : $red-500;
        }

        &.hwx-badge-top {
            background-color : $blue-300;
        }
    }
}

.hwx-tags-pills {
    @include gap(5px);
    display   : flex;
    flex-wrap : wrap;

    .hwx-tags-pill {
        background-color : $lmdb-details-color;
    }
}

img.img-object-fit {
    width           : 100%;
    height          : 100%;
    object-fit      : cover;
    object-position : center center;
}
