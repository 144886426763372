// px to rem, but also keeps string values like none or normal
@mixin rem($property, $value, $context: $base-font-size, $important: false) {
    @if (type-of($value) != number or unit($value) == '%') {
        #{$property}: $value;
    } @else {
        $value      : addUnit($value);
        @if ($important == true) {
            #{$property}: $value !important;
            #{$property}: rem($value, $context) !important;
        } @else {
            #{$property}: $value;
            #{$property}: rem($value, $context);
        }
    }
}

// use for margin and padding only. For other the mixin should be renewed
@mixin rem-multivalue($property, $top, $right: null, $bottom: null, $left: null, $important: false) {
    //@debug "$top: #{$top}";
    //@debug "$right: #{$right}";
    //@debug "$bottom: #{$bottom}";
    //@debug "$left: #{$left}";
    // TODO: auto u.Ä. berücksichtigen
    $top    : addUnit($top);
    $right  : addUnit($right);
    $left   : addUnit($left);
    $bottom : addUnit($bottom);

    @if ($important == true) {
        #{$property}: $top $right $bottom $left !important;
        #{$property}: rem($top) rem($right) rem($bottom) rem($left) !important;
    } @else {
        #{$property}: $top $right $bottom $left;
        #{$property}: rem($top) rem($right) rem($bottom) rem($left);
    }
}

// shorts for highly reused mixins
@mixin max-width($value, $important: false) {
    @include rem(max-width, $value, $important : $important);
}

@mixin min-width($value, $important: false) {
    @include rem(min-width, $value, $important : $important);
}

@mixin width($value, $important: false) {
    @include rem(width, $value, $important : $important);
}

@mixin max-height($value, $important: false) {
    @include rem(max-height, $value, $important : $important);
}

@mixin min-height($value, $important: false) {
    @include rem(min-height, $value, $important : $important);
}

@mixin height($value, $important: false) {
    @include rem(height, $value, $important : $important);
}

@mixin line-height($value, $important: false) {
    @include rem(line-height, $value, $important : $important);
}

@mixin top($value, $important: false) {
    @include rem(top, $value, $important : $important);
}

@mixin right($value, $important: false) {
    @include rem(right, $value, $important : $important);
}

@mixin bottom($value, $important: false) {
    @include rem(bottom, $value, $important : $important);
}

@mixin left($value, $important: false) {
    @include rem(left, $value, $important : $important);
}

@mixin gap($value) {
    @include rfs($value, gap);
}

@mixin grid-gap($value) {
    @include rfs($value, grid-gap);
}

// font
/* https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6 */
@mixin font-face($font-family, $url, $font-weight: null, $font-style: null, $exts: eot woff2 woff ttf svg) {
    $src     : null;

    $extmods : (
            eot: "?",
            svg: "#" + str-replace($font-family, " ", "_")
    );

    $formats : (
            otf: "opentype",
            ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod : if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format : if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src    : append($src, url(quote($url + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family  : quote($font-family);
        font-style   : $font-style;
        font-weight  : $font-weight;
        font-display : swap;
        src          : $src;
    }
}

@mixin button-font($font-family: $font-family-base) {
    @include font-size(16px);
    font-family    : $font-family, serif;
    font-weight    : 700;
    text-transform : uppercase;
    color          : $white;
}

// buttons
@mixin primary-button($font-family: $font-family-base) {
    @include button-font($font-family);

    background-color : $primary;
    border           : none;
    border-radius    : 0;
    cursor           : pointer;
}

// tables
@mixin make-stripes($selector: tr, $odd-color: $table-row-odd-color, $even-color: $table-row-even-color) {
    #{$selector}:nth-of-type(odd) {
        background-color : $odd-color;
    }

    #{$selector}:nth-of-type(even) {
        background-color : $even-color;
    }
}

// text effects
@mixin make-horizontal-textlines($margin-left, $margin-right, $height) {
    display         : flex;
    align-items     : center;
    justify-content : center;

    &:before,
    &:after {
        background : $black;
        height     : addUnit($height);
        flex       : 1;
        content    : '';
    }

    span {
        @include rem(margin-right, $margin-right);
        @include rem(margin-left, $margin-left);
    }
}

@mixin breakwords() {
    overflow-wrap   : break-word;
    word-wrap       : break-word;
    -webkit-hyphens : auto;
    -ms-hyphens     : auto;
    -moz-hyphens    : auto;
    hyphens         : auto;
}

// html elements
@mixin make-sticky() {
    &.hwx-fixed-top,
    &.hwx-fixed-bottom,
    &.hwx-sticky-top,
    &.hwx-sticky-bottom {
        left  : 0;
        right : 0;
    }

    &.hwx-sticky-top,
    &.hwx-sticky-bottom {
        position : absolute;
    }

    &.hwx-fixed-top,
    &.hwx-fixed-bottom {
        position : fixed;
    }

    &.hwx-fixed-top,
    &.hwx-sticky-top {
        top : 0;
    }

    &.hwx-fixed-bottom,
    &.hwx-sticky-bottom {
        bottom : 0;
    }
}

@mixin make-link-border($width: 2px) {
    &.hwx-link-border-top {
        border-top : $width solid $hwx-menu-link-border-top-color;
    }

    &.hwx-link-border-right {
        border-right : $width solid $hwx-menu-link-border-right-color;
    }

    &.hwx-link-border-bottom {
        border-bottom : $width solid $hwx-menu-link-border-bottom-color;
    }

    &.hwx-link-border-left {
        border-left : $width solid $hwx-menu-link-border-left-color;
    }
}

@mixin reset-a-elements() {
    a,
    a:hover {
        text-decoration : none;
        color           : $white;
    }
}

@mixin headings {
    h1, h2, h3, h4, h5, h6 {
        @content;
    }
}

@mixin make-equal-width($selector, $nrOfItems, $defaultWidth: 100%) {
    #{$selector} {
        width : $defaultWidth / $nrOfItems;
    }
}

@mixin make-vertical-spacing($spacing: $grid-gutter-width-half, $type: padding, $child-selector: div) {
    @include rem(margin-top, -$spacing);
    @include rem(margin-bottom, -$spacing);

    & > #{$child-selector} {
        @include rem(#{$type}-top, $spacing);
        @include rem(#{$type}-bottom, $spacing);
    }
}

@mixin make-horizontal-spacing($spacing: $grid-gutter-width-half, $type: padding, $child-selector: div) {
    @include rem(margin-left, -$spacing);
    @include rem(margin-right, -$spacing);

    & > #{$child-selector} {
        @include rem(#{$type}-left, $spacing);
        @include rem(#{$type}-right, $spacing);
    }
}

@mixin make-spacing($vertical: $grid-gutter-width-half, $horizontal: $vertical, $type: padding, $child-selector: div) {
    @include make-vertical-spacing($vertical, $type, $child-selector);
    @include make-horizontal-spacing($horizontal, $type, $child-selector);
}

@mixin nav-link-color($color,  $hover-color) {
    color : $color;

    @include hover-focus {
        color : $hover-color;
    }
}

// config getter
@mixin zindex($key, $add: 0) {
    z-index : map-get($z-index, $key) + $add;
}

// media queries
@mixin target-ie10-11 {
    @media all and (-ms-high-contrast : none), (-ms-high-contrast : active) {
        @content;
    }
}

// .active hinzufügen, da Joomla .active an li im Menü anhängt.
@mixin hover-focus-active($activeClass : false) {
    @if ($activeClass == true) {
        &.active,
        &:hover,
        &:focus,
        &:active {
            @content;
        }
    } @else {
        &:hover,
        &:focus,
        &:active {
            @content;
        }
    }
}

// Wenn man z.B. an einem Link die Farbe ändern möchte, ohne die Hoverfarbe zu verändern
@mixin not-hover-focus-active($activeClass : false) {
    @if ($activeClass == true) {
        &:not(.active):not(:hover):not(:focus):not(:active) {
            @content;
        }
    } @else {
        &:not(:hover):not(:focus):not(:active) {
            @content;
        }
    }
}

@mixin hover-focus() {
    &:hover,
    &:focus {
        @content;
    }
}

// css overrides
@mixin min($property, $min, $max) {
    #{$property}: unquote('min(#{$min}, #{$max})');
}

@mixin max($property, $min, $max) {
    #{$property}: unquote('max(#{$min}, #{$max})');
}

@mixin multiline-truncate($max-lines) {
    display            : -webkit-box;
    -webkit-line-clamp : $max-lines;
    -webkit-box-orient : vertical;
    overflow           : hidden;
}
