.hwx-lmdb-weather-module-default {
    @include font-size(13px);
    @include rem(gap, 20);
    display               : grid;
    grid-template-columns : 1fr 1fr;

    @include media-breakpoint-down(lg) {
        @include padding-top(10px);
        @include padding-bottom(10px);
        background    : $gray-900;
        color         : $white;
        justify-items : center;
    }

    .hwx-lmdb-weather {
        .hwx-lmdb-weather-data {
            display               : grid;
            grid-template-areas   : 'content-right icon';
            grid-template-columns : 2fr 1fr;
            align-items           : center;
            justify-items         : center;

            @include media-breakpoint-up(lg) {
                @include rem(row-gap, 10);
                @include rem(column-gap, 20);
                grid-template-areas   : 'icon content-right' 'content-bottom content-bottom';
                grid-template-columns : auto 1fr;
                justify-items         : flex-start;
            }

            .hwx-lmdb-weather-icon {
                grid-area : icon;

                i {
                    @include font-size(20px);

                    @include media-breakpoint-up(md) {
                        @include font-size(30px);
                    }
                }
            }

            .hwx-lmdb-weather-content {
                grid-area : content-right;

                > .hwx-lmdb-weather-date {
                    @include font-size(12px);
                    @include padding-right(12px);
                    align-self : center;

                    @include media-breakpoint-up(md) {
                        @include font-size(16px);
                        font-weight : $font-weight-bold;
                    }
                }

                .hwx-lmdb-weather-temperatures {
                    @include media-breakpoint-down(lg) {
                        @include padding-right(12px);
                    }

                    .hwx-lmdb-weather-real-temperatures {
                        > span.hwx-lmdb-weather-temperature-high {
                            @include font-size(15px);

                            @include media-breakpoint-up(lg) {
                                @include font-size(18px);
                            }
                        }

                        > span.hwx-lmdb-weather-temperature-low {
                            display : none;
                        }
                    }
                }
            }

            .hwx-lmdb-weather-summary {
                @include font-size(10px);
                grid-area : content-bottom;

                @include media-breakpoint-down(lg) {
                    display : none;
                }
            }
        }
    }
}
